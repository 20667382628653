import React, {useState, useEffect} from 'react'
import styled from '@emotion/styled'
import {motion} from 'framer-motion'

import {emailValidator} from '../../utils/validators'
import {MEDIA} from '../../utils/constants'

const errorMessageVariants = {
  closed: {opacity: 0, y: '-10px', visibility: 'hidden'},
  open: {opacity: [1, 1, 1, 1, 0], y: [-10, 0, 0, -100], visibility: 'visible'},
}

const Newsletter = () => {
  const [agreeCheck, setAgreeCheck] = useState(false)
  const [emailError, setEmailError] = useState(false)

  const [serverError, setServerError] = useState(null)

  const [subName, setSubName] = useState('')
  const [subEmail, setSubEmail] = useState('')

  useEffect(() => {
    setServerError(null)
    // console.log('serverError',serverError)
  }, [emailError])

  const validator = (e) => {
    e.preventDefault()
    setServerError(null)
    // console.log('enter subscribeHandler')
    if (!emailValidator(subEmail)) {
      // console.log('error in email')
      setEmailError(true)
      // console.log('emailError ()', emailError)
    } else {
      subscribeHandler()
    }
  }

  const subscribeHandler = () => {
    const data = {
      Name: subName,
      Email: subEmail,
    }
    fetch(`${process.env.GATSBY_API_URL}/subscribers`, {
      // fetch(`http://localhost:1337/subscribers`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log('res', res)
        if (res.error) {
          console.log('res.message', res.message)
          setServerError(res.message)
        }
        setSubName('')
        setSubEmail('')
        setAgreeCheck(false)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }
  return (
    <StyledDiv>
      <h2>Stay in touch with our newsletter:</h2>

      <Container data-css='Newsletter-form-container'>
        <NewsletterRegForm data-css='Newsletter-form'>
          <Names>
            {/* <label> */}
            <input
              type='text'
              name='Name'
              placeholder='Name'
              value={subName}
              onChange={(e) => setSubName(e.target.value)}
            />
            {/* </label> */}
            {/* <label> */}
            <Input
              type='email'
              name='subEmail'
              placeholder='email@place.com'
              value={subEmail}
              error={emailError}
              onChange={(e) => {
                setSubEmail(e.target.value)
                setEmailError(false)
              }}
            />
            {/* </label> */}
          </Names>
          <AgreeContainer>
            <Input
              type='checkbox'
              name='agree'
              checked={agreeCheck}
              // error={validationError.agreeCheck}
              onChange={(e) => setAgreeCheck(e.target.checked)}
            ></Input>
            <label htmlFor='agree'>
              <p>I acknowledge information will be </p>
              <p>retained for newsletter purposes only</p>
            </label>
            <SubmitBtn
              onClick={validator}
              flag={agreeCheck}
              // {agreeCheck ? }
              disabled={!agreeCheck}
              whileHover={{scale: 1.1}}
              whileTap={{scale: 0.9}}
            >
              Subscribe
            </SubmitBtn>
          </AgreeContainer>
        </NewsletterRegForm>
        <Error
          data-css='error'
          animate={serverError ? 'open' : 'closed'}
          transition={{duration: 1.5}}
          variants={errorMessageVariants}
        >
          <p>{serverError}</p>
        </Error>
      </Container>
    </StyledDiv>
  )
}

export default Newsletter

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  color: #0f4c81;
  h2 {
    margin: 1rem 2rem -1rem 2rem;
    padding: 0.4rem 0 0.4rem 0;
    background-color: whitesmoke;
    border-radius: 2px;
  }
  -webkit-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
`

const Container = styled.div`
  display: flex;
  width: 90%;
  margin: 0 auto;
  align-items: center;
  @media (min-width: ${MEDIA.tablet}) {
    width: 75%;
  }
`

const NewsletterRegForm = styled.form`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 1rem auto;
  padding: 10px;
  /* background-color: white; */
  border-radius: 0 0 0.25rem 0.25rem;
`

const Names = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  input {
    margin: 0 auto;
  }

  @media (min-width: ${MEDIA.tablet}) {
    width: fit-content;
    flex-direction: row;
    input {
      margin: 0 0.5rem;
    }
  }
`

const AgreeContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  label {
    margin: 0.25rem 0.5rem;
  }
  p {
    color: lightgray;
    font-size: 0.75rem;
  }
`

const SubmitBtn = styled(motion.button)`
  height: 2rem;
  margin: 0 1rem;
  background-color: #0f4c81;
  color: whitesmoke;
  border: none;
  border-radius: 0.25rem;
  /* visibility: ${(prop) => (prop.flag ? 'visible' : 'hidden')}; */
  filter: ${(prop) => (prop.flag ? 'none' : 'grayScale(100%)')};
  background-color: ${(prop) => (prop.flag ? '#0F4C81' : 'lightgray')};
  &:hover {
    cursor: ${(prop) => (prop.flag ? 'pointer' : 'not-allowed')};
  }
`

const Input = styled.input`
  border: 1px solid ${(prop) => (prop.error ? 'red' : 'transparent')};
`

const Error = styled(motion.div)`
  position: absolute;
  margin: 0 auto;
  width: 100%;
  left: 0;
  opacity: 0;
  p {
    width: fit-content;
    padding: 1rem 3rem;
    margin: 0 auto;
    color: var(--charcoal);
    color: whitesmoke;
    /* color: sienna; */
    background-color: lightsalmon;
    /* background-color: mistyrose; */
    /* border: 2px solid whitesmoke; */
    border-radius: 0.5rem;
    -webkit-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
  }
`
