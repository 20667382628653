import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import Nav from './nav'
import Seo from './seo'
import Newsletter from './ContactForms/Newsletter'
import GlobalStyle from './GlobalStyle'

import curb from '../assets/navbar/test.jpg'

import {MEDIA} from '../utils/constants'
import FontSelector from './FontSelector/FontSelector'

const MainLayout = ({children}) => {
  const [headerFont, setHeaderFont] = useState(3)
  const [contentFont, setContentFont] = useState(1)
  // const headerFont = "Cantata One";
  // const contentFont = "Nunito Sans";

  return (
    <Wrapper data-css='mainLayout-Wrapper'>
      <Seo />
      <GlobalStyle headerFont={headerFont} contentFont={contentFont} />
      {/* <FontSelector 
        headerFont={headerFont} 
        setHeaderFont={setHeaderFont}
        contentFont={contentFont} 
        setContentFont={setContentFont} 
      /> */}
      <Nav />
      <Main data-css='mainLayout-Main'>
        {children}
        <Footer>
          <Newsletter />
        </Footer>
      </Main>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${MEDIA.tablet}) {
    display: grid;
    grid-template-columns: var(--navbar-width) 1fr;
    grid-template-rows: 100%;
    grid-template-areas: 'navbar main';
  }
`

const Main = styled.main`
  grid-area: main;
  overflow: auto;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Footer = styled.footer`
  position: relative;
  bottom: 0;
  min-height: 2rem;
  height: fit-content;
  margin: 0rem 0 0;
  text-align: center;
  background: #3a3633;
  color: white;
  font-weight: bold;
  border-top: 2px solid white;
`

MainLayout.propTypes = {
  children: PropTypes.node,
}

export default MainLayout
