import React from 'react'
import {Link, StaticQuery, graphql} from 'gatsby'
import styled from '@emotion/styled'
import {motion, AnimatePresence} from 'framer-motion'

import {MEDIA} from '../../utils/constants'
// import {useMediaQuery} from '../utils/hooks';

const NavLinks = ({menuShow}) => {
  return (
    <AnimatePresence>
      <LinkList
        initial={{height: 0}}
        animate={{height: menuShow ? 'auto' : 0}}
        exit={{height: 0}}
        transition={{duration: 1}}
        data-css='NavLinksList'
      >
        <StaticQuery
          query={graphql`
            query {
              allStrapiCategory {
                edges {
                  node {
                    strapiId
                    name
                  }
                }
              }
            }
          `}
          render={(data) => {
            // console.log('data',data)
            const pages = ['about', 'contact']
            return pages.map((page, i) => {
              return (
                <li key={`${page}${i}`}>
                  <StyledLink to={`/${page}`} className='headerClass'>
                    {page}
                  </StyledLink>
                </li>
              )
            })
          }}
        />
      </LinkList>
      {/* <BottomLinks
        key='bottomLinks'
        initial={{height: 0, overflow: 'hidden'}}
        animate={{height: menuShow ? 'auto' : 0}}
        exit={{height: 0}}
        transition={{duration: 1}}
        data-css='BottomLinks'
      >
        <li>
          <StyledLink to={'/services'} className='headerClass'>
            Services
          </StyledLink>
        </li>
      </BottomLinks> */}
    </AnimatePresence>
  )
}

export default NavLinks

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 0.5rem;
  overflow: hidden;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.25rem;
  color: black;
  text-shadow: 0 1px 0 silver;
  &:hover {
    color: silver;
    cursor: pointer;
  }
  @media (min-width: ${MEDIA.tablet}) {
  }
  @media (min-width: ${MEDIA.desktop}) {
  }
`
const LinkList = styled(motion.ul)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background: var(--nav-color);
  flex: 1;

  li {
    background: var(--nav-color);
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 1rem 0 1rem;
    text-align: center;
  }
  @media (min-width: ${MEDIA.tablet}) {
    background: transparent;
    position: static;
    height: 100%;
    li {
      display: inline-block;
      background: transparent;
      margin-left: 0.5rem;
      padding: 0.5rem 0;
    }
  }
  @media (min-width: ${MEDIA.desktop}) {
  }
`

const BottomLinks = styled(motion.ul)`
  position: absolute;

  bottom: 0;
  list-style-type: none;
  li {
    background: var(--nav-color);
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 1rem 0 1rem;
    text-align: center;
  }
`
