import React from 'react'
import {Link} from 'gatsby'
import styled from '@emotion/styled'
import {motion} from 'framer-motion'

import {MEDIA} from '../../utils/constants'
import logo2 from '../../assets/placeholderImages/revisedlogos/jlcleardark.png'

const JusticeLogo = ({children}) => {
  let name = 'The Justice Library'
  return (
    <LogoContainer to={'/'}>
      <Logo>
        <img src={logo2} alt='site logo' />
        <LogoText>
          <div style={{position: 'relative', zIndex: '4'}}>
            {name.split(' ').map((word, i) => (
              <p key={i} className='headerClass'>
                {word}
              </p>
            ))}
          </div>
        </LogoText>
      </Logo>
    </LogoContainer>
  )
}

export default JusticeLogo

const LogoContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -0.5rem 0 0 0;
  width: 100%;
  height: 100px;
  max-height: 100%;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  @media (min-width: ${MEDIA.tablet}) {
    background: rgba(33, 37, 41, 0.9);
    background: linear-gradient(
      180deg,
      rgba(4, 4, 4, 1) 0%,
      rgba(4, 4, 4, 0.7521875) 66%,
      rgba(109, 127, 127, 0) 99%
    );
    background: linear-gradient(
      180deg,
      rgba(4, 4, 4, 1) 37%,
      rgba(4, 4, 4, 0.7521875) 72%,
      rgba(109, 127, 127, 0) 99%
    );
  }
  @media (min-width: ${MEDIA.desktop}) {
  }
`
const Logo = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 3rem;
  margin: 0;
  margin-left: -1.5rem;
  border-radius: 0;
  border: 3px inset var(--charcoal);
  box-sizing: border-box;
  background: whitesmoke;
  -moz-box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.25),
    1px 1px 15px 2px rgba(0, 0, 0, 0.25) inset;
  -webkit-box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.25),
    1px 1px 15px 2px rgba(0, 0, 0, 0.25) inset;
  box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.25),
    1px 1px 15px 2px rgba(0, 0, 0, 0.25) inset;
  img {
    height: 90%;
  }
  @media (min-width: ${MEDIA.tablet}) {
    padding: 0 0;
    margin-left: 0;
    img {
      height: 90%;
    }
  }
  @media (min-width: ${MEDIA.desktop}) {
  }
`
const LogoText = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    text-transform: uppercase;
    color: black;
    font-size: 1rem;
    text-align: center;
    line-height: 1;
    text-shadow: 0 1px 0 whitesmoke, 0 0 1px rgba(255, 255, 255, 0.6),
      0 0 2px rgba(255, 255, 255, 0.7), 0 0 3px rgba(255, 255, 255, 0.8),
      0 0 4px rgba(255, 255, 255, 0.9);
  }
  @media (min-width: 768px) {
    p {
      font-size: 1.3rem;
    }
  }

  @media (min-width: 1024px) {
  }
`
