import React, {useState} from 'react'
import {Link} from 'gatsby'
import styled from '@emotion/styled'
// import { motion, AnimatePresence } from 'framer-motion';
import {GiHamburgerMenu} from 'react-icons/gi'

// import logo from '../assets/placeholderImages/logos/8.png'
// import logo2 from '../assets/placeholderImages/revisedlogos/jlcleardark.png'
// import bm1 from '../assets/placeholderImages/bm1.png'
// import bm2 from '../assets/placeholderImages/bm2.jpg'
import JLLogo from '../assets/logo/JLLogo.png'

import curb from '../assets/navbar/test.jpg'
import {MEDIA} from '../utils/constants'
// import { useMediaQuery } from '../utils/hooks';

import JusticeLogo from '../components/JusticeLogo'
import NavLinks from '../components/NavLinks'

const Nav = () => {
  const [menuShow, setMenuShow] = useState(false)
  // const gate = useMediaQuery();

  return (
    <StyledDiv>
      <Sides>
        <JusticeLogo />
        {/* <img src={JLLogo} alt="Justice Library Logo"/> */}
      </Sides>
      <MobileNav data-css='nav-mobile-render'>
        <Sides
          onMouseLeave={() => {
            setMenuShow(false)
          }}
        >
          <Menu onClick={() => setMenuShow(!menuShow)}>
            <GiHamburgerMenu />
          </Menu>
          <NavLinks menuShow={menuShow} />
        </Sides>
      </MobileNav>

      <TabletNav data-css='nav-tabletandup-render'>
        <Sides>
          <NavLinks menuShow={true} />
        </Sides>
      </TabletNav>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--nav-height);
  background: var(--nav-color);

  @media (min-width: ${MEDIA.tablet}) {
    height: 100vh;
    flex-direction: column;
    position: sticky;
    left: 0;
    top: 0;
    background-repeat: repeat;
    background: var(--nav-color);
    box-shadow: 5px 0px 10px 2px rgba(0, 0, 0, 0.4);
  }
`

const Sides = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  height: 100%;
  flex: 1;

  @media (min-width: ${MEDIA.tablet}) {
    padding: 0;
    height: fit-content;
    width: 100%;
    margin-top: 0.25rem;
  }
  @media (min-width: ${MEDIA.desktop}) {
    width: 100%;
  }
`

const MobileNav = styled.div`
  height: 100%;
  z-index: 10;
  @media (min-width: ${MEDIA.tablet}) {
    display: none;
  }
`
const TabletNav = styled.div`
  display: none;
  @media (min-width: ${MEDIA.tablet}) {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
`

const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1.5rem;
  padding: 1rem;
  color: var(--charcoal);
  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${MEDIA.tablet}) {
    display: none;
  }
  @media (min-width: ${MEDIA.desktop}) {
    /* display: none; */
  }
`

export default Nav
